import React from 'react';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';

export default props => (
  <Layout>
    <Seo title="thank you!!" description="thank you!!" href={props.location.href} />
    <div className="thanks">
      <h1 className="thanks__title">送信完了</h1>
      <p className="thanks__content">お問い合わせありがとうございました。</p>
    </div>
  </Layout>
);
